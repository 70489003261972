<template>
  <div class="page__case-add">
    <y-form
      style="width: 100%"
      label-width="100px"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitText: '确定',
        resetText: '取消'
      }"
      @submit="submit"
      @reset="reset"
    ></y-form>
  </div>
</template>

<script>
import { uid } from 'uid'
import Upload from '@/components/upload'
import Editor from '@/components/editor'

export default {
  name: 'BannerAdd',
  data() {
    return {
      typeList: [],
      fetchTypeListLoading: false,
      model: {
        title: '',
        subTitle: '',
        type: '',
        file: null,
        topFile: null,
        content: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '标题',
            prop: 'title'
          }
        },
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '副标题',
            prop: 'subTitle'
          }
        },
        {
          formItemProps: {
            style: 'width: 460px',
            label: '类型',
            prop: 'type'
          },
          render: (h, prop, field, model) => {
            return (
              <el-select
                style="width: 100%;"
                v-model={model[prop]}
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                remoteMethod={this.fetchTypeList}
                vOn:focus={this.fetchTypeListFocus}
                loading={this.loading}
              >
                {this.typeList.map((item) => (
                  <el-option
                    key={item.value}
                    label={item.label}
                    value={item.value}
                  ></el-option>
                ))}
              </el-select>
            )
          }
        },
        {
          formItemProps: {
            style: 'width: 460px',
            label: '图片',
            prop: 'file'
          },
          render(h, prop, field, model) {
            return <Upload v-model={model[prop]}></Upload>
          }
        },
        {
          formItemProps: {
            style: 'width: 460px',
            label: '顶部图片',
            prop: 'topFile'
          },
          render(h, prop, field, model) {
            return <Upload v-model={model[prop]}></Upload>
          }
        },
        {
          formItemProps: {
            label: '内容',
            prop: 'content'
          },
          render(h, prop, field, model) {
            return <Editor v-model={model[prop]}></Editor>
          }
        }
      ],
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }]
      }
    }
  },
  computed: {
    caseId() {
      return this.$route.params.id
    }
  },
  watch: {
    caseId: {
      handler(val) {
        if (val) this.fetchInfo()
      },
      immediate: true
    }
  },
  methods: {
    async fetchInfo() {
      const res = await this.$serve.caseInfo({
        aimid: this.caseId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.model.title = res.title
      this.model.subTitle = res.subTitle
      this.model.type = res.industryType
      if (res.industryType) {
        this.typeList = [
          {
            label: res.industryTypeName,
            value: res.industryType
          }
        ]
      }
      if (res.imgUrl) {
        this.model.file = {
          uid: uid(),
          status: 'success',
          url: res.imgUrl
        }
      }
      if (res.topImgUrl) {
        this.model.topFile = {
          uid: uid(),
          status: 'success',
          url: res.topImgUrl
        }
      }
      this.model.content = res.context
      {/* if (res.contextUrl) this.fetchContent(res) */}
    },
    async fetchContent(record) {
      const res = await this.$serve.oss({
        urlQuery: record.contextUrl
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.model.content = res
    },
    async fetchTypeList(keyword) {
      this.loading = true
      const res = await this.$serve.typeList({
        data: {
          pageSize: 100,
          keyword
        }
      })
      this.loading = false
      if (res === this.$serve.FAIL) {
        return
      }
      this.typeList = res.records.map((item) => ({
        label: item.name,
        value: item.id
      }))
    },
    async fetchTypeListFocus() {
      if (!this.typeList.length) {
        this.fetchTypeList('')
      }
    },
    async submit({ content, file, topFile, type, ...data }) {
      data.context = content
      data.industryType = type
      if (file?.response?.data || file?.url) {
        data.imgUrl = file.response?.data || file.url
      }
      if (topFile?.response?.data || topFile?.url) {
        data.topImgUrl = topFile.response?.data || topFile.url
      }
      if (this.caseId) {
        data.id = this.caseId
        this.update(data)
      } else {
        this.add(data)
      }
    },
    async add(data) {
      const res = await this.$serve.caseAdd({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('新闻添加成功')
      this.$router.push('/case')
    },
    async update(data) {
      const res = await this.$serve.caseUpdate({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('新闻修改成功')
      this.$router.push('/case')
    },
    reset() {
      this.$router.push('/case')
    }
  }
}
</script>

<style lang="scss" scoped>
.page__cases-add {
  ::v-deep .upload .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
}
</style>
